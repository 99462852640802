import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import Card from '../components/Card';
import Col from '../components/Col';
import Hero from '../components/Hero';
import Row from '../components/Row';
import Seo from '../components/SEO';

const OpenProcessesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      categories: allCategory(limit: 1000) {
        nodes {
          id
          title
          description
          slug
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.5)
            }
          }
        }
      }
    }
  `);

  const categories = data.categories.nodes;

  return (
    <div>
      <Seo
        title='Open Processes'
        description='We Open-Source our Workflows. Copy what works for us!'
      />
      <Hero container='sm'>
        <h1 className='typo-h1 text-center max-w-6xl'>
          We Open-Source our Workflows. Copy what works for us!
        </h1>
      </Hero>
      <Row cols={12} className='container-md '>
        {categories.map((category) => (
          <Col xs={12} md={6} key={category.id}>
            <Card url={category.slug}>
              <Card.Image image={category.image} alt={category.title} />
              <Card.Title>{category.title}</Card.Title>
              <Card.Description>{category.description}</Card.Description>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default OpenProcessesPage;
